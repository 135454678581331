// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/opt/build/repo/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-capodanno-js": () => import("/opt/build/repo/src/pages/capodanno.js" /* webpackChunkName: "component---src-pages-capodanno-js" */),
  "component---src-pages-cook-js": () => import("/opt/build/repo/src/pages/cook.js" /* webpackChunkName: "component---src-pages-cook-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poi-js": () => import("/opt/build/repo/src/pages/poi.js" /* webpackChunkName: "component---src-pages-poi-js" */),
  "component---src-pages-rooms-js": () => import("/opt/build/repo/src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-story-js": () => import("/opt/build/repo/src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-wines-js": () => import("/opt/build/repo/src/pages/wines.js" /* webpackChunkName: "component---src-pages-wines-js" */)
}

