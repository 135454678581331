import React from 'react'
import { Location } from '@reach/router'
import { injectIntl } from 'gatsby-plugin-intl'
import Helmet from 'react-helmet'

const Seo = props => (
  <Location>
    {locationProps => {
      // Declarations here are for
      // seo purpose
      // path: is the path of the page ex. /en/wines /it/wines
      const path = locationProps.location.pathname
      // prefix if the page without /en or /it or /another_lang
      const prefix = path.replace(/^\/[a-z]{2}\//, '')
      // pageName id a path striped without \/
      const pageName = prefix.replace(/\//g, '')
      // Images for facebook are store in Assets/og_images
      // og-image is the default one, that image cannot be deleted
      const ogImage = `/og-images/${
        pageName !== '' ? pageName : 'og-image'
      }.jpg`

      const href = locationProps.location.href
      const { intl } = props

      const title = `${intl.formatMessage({
        id: 'generalTitle',
      })} - ${intl.formatMessage({ id: pageName + 'SeoTitle' })}`

      const description = intl.formatMessage({
        id: `${pageName}SeoDescription`,
      })

      return (
        <Helmet>
          <title>{title}</title>
          <meta name='description' content={description} />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta property='og:url' content={href} />
          <meta property='og:image' content={ogImage} />
        </Helmet>
      )
    }}
  </Location>
)

export default injectIntl(Seo)
